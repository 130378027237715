<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 mb-2"
    >
      <div class="d-flex align-center">
        <span class="input-label mr-4">Wprowadzana Wartość</span>
        <v-radio-group
          v-model="isNetInputActive"
          :disabled="disabled"
          row
          dense
          hide-details
          class="pt-0"
        >
          <v-radio
            label="Netto"
            :value="true"
          />
          <v-radio
            label="Brutto"
            :value="false"
          />
        </v-radio-group>
      </div>
    </v-col>

    <v-col
      cols="5"
      v-if="isNetInputActive"
      class="pb-0"
    >
      <v-text-field
        ref="netValueInput"
        outlined
        :label="'Cena netto ' + label + ' [zł]'"
        v-model.number="netValue"
        type="number"
        validate-on-blur
        :max="maxValue"
        :rules="[rules.required,rules.money, rules.lowerThan(maxValue)]"
        :disabled="disabled"
        @wheel="$event.target.blur()"
        placeholder="Wpisz cenę netto"
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0"
      v-else
    >
      <div class="input-label">
        Cena netto {{ label }} [zł]
      </div>
      <div>{{ +netAmount | significantFigures }} zł</div>
    </v-col>

    <v-col
      cols="2"
      class="pt-0"
    >
      <div class="input-label">
        Vat
      </div>
      <div>{{ taxValue }}%</div>
    </v-col>

    <v-col
      cols="5"
      v-if="!isNetInputActive"
      class="pb-0"
    >
      <v-text-field
        ref="grossValueInput"
        outlined
        :label="'Cena brutto ' + label + ' [zł]'"
        v-model.number="grossValue"
        type="number"
        validate-on-blur
        :max="maxValue"
        :rules="[rules.required,rules.money, rules.lowerThan(maxValue)]"
        :disabled="disabled"
        @wheel="$event.target.blur()"
        placeholder="Wpisz cenę brutto"
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0"
      v-else
    >
      <div class="input-label">
        Cena brutto {{ label }} [zł]
      </div>
      <div>{{ localGrossValue | significantFigures }} zł</div>
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'
import { getNetValue, getGrossValue, roundToDecimalPoint } from '../../../utils'

export default {
  filters: {
    significantFigures (value) {
      return value ? value.toFixed(2) : '0.00'
    }
  },
  props: {
    netAmount: {
      type: [Number, String, Object],
      default: 0,
    },
    label: {
      type: String,
      default: ''
    },
    taxValue: {
      type: Number,
      required: true
    },
    startWithGross: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focusInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      isNetInputActive: true,
      localGrossValue: 0,
      localNetValue: 0,
      localNetValueSetByGrossValue: null, // needed because of setting net value by gross value and also watching netAmount (netValue get) for changes from other sources
      maxValue: 10000001
    }
  },
  computed: {
    grossValue: {
      get () {
        return this.localGrossValue
      },
      set (grossValue) {
        if (/^\d+\.\d{3}$/.test(grossValue)) return // prevent from setting value with more than 2 decimal points - it's gonna trigger validation error
        this.localGrossValue = grossValue
        this.localNetValueSetByGrossValue = roundToDecimalPoint(this.getNetValue(grossValue), 2)
        this.$emit('update', {
          netValue: roundToDecimalPoint(this.getNetValue(grossValue), 2),
          grossValue,
          origin: 'R' // price origin is set manually representet by "Ręcznie"
        })
      }
    },
    netValue: {
      get () {
        return this.netAmount
      },
      set (netValue) {
        if (/^\d+\.\d{3}$/.test(netValue)) return // prevent from setting value with more than 2 decimal points - it's gonna trigger validation error
        this.localGrossValue = roundToDecimalPoint(this.getGrossValue(netValue), 2)
        this.$emit('update', {
          grossValue: roundToDecimalPoint(this.getGrossValue(netValue), 2),
          netValue,
          origin: 'R' // price origin is set manually representet by "Ręcznie"
        })
      }
    }
  },
  watch: {
    netAmount(value) {
      if (!this.isNetInputActive) {
        this.localNetValue = value
      }
      if (value !== this.localNetValueSetByGrossValue) {
        this.localGrossValue = roundToDecimalPoint(this.getGrossValue(value), 2)
      }
    }
  },
  created() {
    if (this.startWithGross) this.isNetInputActive = false
    if (this.netAmount) {
      this.localNetValue = this.netAmount
      this.localGrossValue = roundToDecimalPoint(this.getGrossValue(this.netAmount), 2)
    }

    if (this.focusInput) {
      this.$nextTick().then(() => {
        this.isNetInputActive ? this.$refs.netValueInput.focus() : this.$refs.grossValueInput.focus()
      })
    }
  },
  methods: {
    getNetValue(value) {
      return getNetValue(value, this.taxValue)
    },
    getGrossValue(value) {
      return getGrossValue(value, this.taxValue)
    },
  }
}
</script>
