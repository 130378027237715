<template>
  <PaymentRow
    :net-amount="netAmountInPLN"
    :tax-value="data.payment.vatPercentage"
    :start-with-gross="field.gross"
    @update="updateValue($event)"
  />
</template>

<script>
import PaymentRow from '../Partials/PaymentRow'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
  },
  components: {
    PaymentRow,
  },
  data: () => ({
    netAmountInPLN: null,
  }),
  created () {
    this.netAmountInPLN = +this.data.payment?.unitDebrisNetValue / 100
  },
  methods: {
    updateValue ({ netValue, grossValue }) {
      this.netAmountInPLN = netValue
      const { aggregateAmount } = this.data
      const emitted = {
        debrisNetValue: +netValue * 100 * (aggregateAmount || 1),
        debrisGrossValue: +grossValue * 100 * (aggregateAmount || 1),
      }
      this.$emit('update', emitted)
    }
  }
}
</script>
